import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import SEO from "../components/seo";
import Video from '../components/video';
import PageLayout from '../components/page-layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title={"Intro"} mdxType="SEO" />
    <h1>{`AvoBot`}</h1>
    <p>{`AvoBot is an Open Source/Open Hardware hobby project which allows building a low-cost platform for experiments with
SLAM and navigation using ROS.  `}</p>
    <p>{`The project started as me trying to experiment with autonomous indoor navigation.`}<br parentName="p"></br>{`
`}{`Robot platforms that you can buy are not cheap and usually bare-bones.`}<br parentName="p"></br>{`
`}{`They either have to cover, or not extensible enough.`}<br parentName="p"></br>{`
`}{`I decided to build a robot myself using cheap components and 3d-printed parts.`}<br parentName="p"></br>{`
`}{`This site shares all of the files needed to build it and software needed to run it.`}<br parentName="p"></br>{`
`}{`AvoBot doesn't do anything useful and is intended for educational purposes only.`}<br parentName="p"></br>{`
`}{`Because it's 3d printed it's easily extensible, if you want to install a camera on it you can modify front cover and put a camera mount in it.`}<br parentName="p"></br>{`
`}{`If you need to install a dedicated AI card you can make the robot taller, print some mounts for it and you are all set :-)`}<br parentName="p"></br>{`
`}{`Electronics on AvoBot are as simple as they can be to be able able to run ROS 2.`}<br parentName="p"></br>{`
`}{`The ROS runs on Raspberry Pi 3 with arm64 Ubuntu as the base OS.`}<br parentName="p"></br>{`
`}{`Arduino is used for low-level hardware stuff which needs real-time OS (interrupts and PWM for motor control).`}<br parentName="p"></br>{`
`}{`Motors are cheap brushed ones with built-in encoders. They are no match for Turtlebot's DYNAMIXEL servos, but they are good enough for mapping and navigation with Lidar.`}<br parentName="p"></br>{`
`}{`It has a lidar and front bumpers for obstacle avoidance and navigation.`}</p>
    <p>{`You'll need a 3D printer to build the robot and some basic soldering skills.`}</p>
    <p>{`Here is a 3D design:
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/462527bb5f17dbd866d443faa03c2e30/cd78c/chassis.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAABJ0AAASdAHeZh94AAACy0lEQVQ4y22Ta0iTURjH3y99CfpSLAixjEqUoBvZnS3KRIyMlPBSmERaRjW7SHgPxUmY5qW8YiyGF1LMIINILMt5y1wy3Wze2N7N3XT6LjP3Xs7T2TumWxPOh3N5fs/z/P/nHALAsM5AJAIjAgvN6ABI3wCOI1lWR/gc6AEsAHYng1T8kuJ39KsxCOkxiSeEL4noIbDlTigul1SdfloRuaDNAeYH8uAZRof5/2CedPQyU0KHcpv257HxSWlLa0L/WwGaFiJ6wFUf13SR3jDCnfxmjY9A499eG/imfIdl8Iih97BBvo9RBzDmHKyFZWewWoDZdeFF1lbNqv0XFUHdTbvrC/3UH/fAzF4Mc1QDBxRNT3vKJDzb4IeRthTbR486VIFIs+vPaLB1OMQxV8UhM+MYATAjNLsqnsBtMIzWnUKPEN6lVary71/PybtyK4pvFxZkT02OYcE10phhRRbumY/xMMzlPsOSiMPN69Oz5ysfNssy06JiEy/FxJSlJFs1g3XNV8SS7dbZWoAFhEhPzbgZ3V97J8BUzzcbQXy4s/P4k6TYC3Hxd8PCGs9HUL+6AEZb30V86b8HMMd5X5URcRO0TYaz9g18Eh669Sw6svJqXN7FqMbwo+1hArlUSgO7YG0bHsnFvnpXdi5spvHH4Oiv7st48TJUeOJMYkRkvVjcliRKFt0gCKq1ZQxQz7ypA2DeC+alLikH87ukwaXdadHPAyTFIdeuny2pe21tKgjdWrRxs5rUaQCsHGddc3vVMN5Grazi1NBQTXpHaltH0CvZ/oyy8PeKhLqW+KYGCbaD5fScs4xhDeYQyTIkn8+0sjKxvKzGqjq7K/PubynMD0hJFYhvbijK9Ftewv/EhK+WH27DWKRjWdL9vPExnphtcwpJlihcJDh5YNOD5IPyz6VOU93PCU9wCmLdv+rKgpCRopTUopJPZ/eN/AcxTERVTSDz3wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AvoBot",
            "title": "AvoBot",
            "src": "/static/462527bb5f17dbd866d443faa03c2e30/c1b63/chassis.png",
            "srcSet": ["/static/462527bb5f17dbd866d443faa03c2e30/5a46d/chassis.png 300w", "/static/462527bb5f17dbd866d443faa03c2e30/0a47e/chassis.png 600w", "/static/462527bb5f17dbd866d443faa03c2e30/c1b63/chassis.png 1200w", "/static/462527bb5f17dbd866d443faa03c2e30/cd78c/chassis.png 1236w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`And he is how it looks fully assembled:`}<br parentName="p"></br>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b7fc4f90a5d46acc1a27251d68961914/b5c84/avobot_assembled.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABpfVhNawiJ//EABoQAAIDAQEAAAAAAAAAAAAAAAABAgMREkH/2gAIAQEAAQUCjN90WLNPKk2bNH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAcEAACAQUBAAAAAAAAAAAAAAAAAUEQERJRYYH/2gAIAQEABj8C4YyqOGXb8Rs//8QAGxAAAwADAQEAAAAAAAAAAAAAAAERIUFRMZH/2gAIAQEAAT8hdlLx8LtgzmyxbRofwg8VROs//9oADAMBAAIAAwAAABBTz//EABYRAQEBAAAAAAAAAAAAAAAAACEQEf/aAAgBAwEBPxAxn//EABYRAAMAAAAAAAAAAAAAAAAAAAEQMf/aAAgBAgEBPxAVf//EABsQAQEAAwEBAQAAAAAAAAAAAAERACExQWGR/9oACAEBAAE/EKepN1we4hO00OT5gL3HdRowv7h6J3gBb1nuMU7jrM//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "AvoBot assembled",
            "title": "AvoBot assembled",
            "src": "/static/b7fc4f90a5d46acc1a27251d68961914/e5166/avobot_assembled.jpg",
            "srcSet": ["/static/b7fc4f90a5d46acc1a27251d68961914/f93b5/avobot_assembled.jpg 300w", "/static/b7fc4f90a5d46acc1a27251d68961914/b4294/avobot_assembled.jpg 600w", "/static/b7fc4f90a5d46acc1a27251d68961914/e5166/avobot_assembled.jpg 1200w", "/static/b7fc4f90a5d46acc1a27251d68961914/b5c84/avobot_assembled.jpg 1704w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Video videoSrcURL='https://www.youtube.com/embed/odzYdYGRpyk' videoTitle='AvoBot Navigation on ROS 2' mdxType="Video" /> 
    <p><a parentName="p" {...{
        "href": "/build"
      }}>{`How to build it`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/stls"
      }}>{`STLs`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/electronics"
      }}>{`Electronics`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/software"
      }}>{`Software`}</a>{`  `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      